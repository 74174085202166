.loading {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #97785b;
  z-index: 1000;
}

.drop {
  position: absolute;
  top: 0;

  & > img {
    width: 12px;
    height: 18px;
  }
}

.first {
  left: calc(50% - 30px);
  animation: dot-flashing-first 2.5s infinite;
}

.second {
  left: 50%;
  animation: dot-flashing-second 2.5s infinite;
}

.third {
  left: calc(50% + 30px);
  animation: dot-flashing-third 2.5s infinite;
}

@keyframes dot-flashing-first {
  0% {
    opacity: 0;
  }

  12.5% {
    opacity: 1;
    transform: translateY(50vh);
  }

  50% {
    opacity: 1;
    transform: translateY(50vh);
  }

  100% {
    opacity: 0;
    transform: translateY(110vh);
  }
}

@keyframes dot-flashing-second {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
    transform: translateY(50vh);
  }

  62.5% {
    opacity: 1;
    transform: translateY(50vh);
  }

  100% {
    opacity: 0;
    transform: translateY(110vh);
  }
}

@keyframes dot-flashing-third {
  0% {
    opacity: 0;
  }

  37.5% {
    opacity: 1;
    transform: translateY(50vh);
  }

  75% {
    opacity: 1;
    transform: translateY(50vh);
  }

  100% {
    opacity: 0;
    transform: translateY(110vh);
  }
}
