body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url("../public/images/cursor05a.png") 5.5 0, auto;
}

#map {
  cursor: url("../public/images/cursor05a.png") 5.5 0, auto;
}

.map {
  width: 100vw;
  height: 100vh;
}

#root {
  background-color: #97785b;
}

@font-face {
  font-family: BelweStd;
  font-weight: 700;
  src: url("../public/fonts/BelweStd-Bold.otf") format("opentype");
}

@font-face {
  font-family: BelweStd;
  font-weight: 400;
  src: url("../public/fonts/BelweStd-Condensed.otf") format("opentype");
}

@font-face {
  font-family: BelweStd;
  font-weight: 100;
  src: url("../public/fonts/BelweStd-Light.otf") format("opentype");
}

@font-face {
  font-family: BelweStd;
  font-weight: 400;
  src: url("../public/fonts/BelweStd-Medium.otf") format("opentype");
}

@font-face {
  font-family: Amarante;
  src: url("../public/fonts/Amarante-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Aubrey;
  src: url("../public/fonts/Aubrey-Regular-dehinted.ttf") format("truetype");
}
