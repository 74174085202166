.flipCardWrapper {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: transparent;
  z-index: 1000;
  perspective: 2000px;
}

.buttonWrapper {
  padding: 0;
  border: none;
  border-radius: 2.4%/1.7%;
  background: none;
  cursor: url("../../../public/images/cursor06a.png") 5.5 0, auto;
  transform-style: preserve-3d;
  outline: 0;
}

.flipCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 2.4%/1.7%;
  color: inherit;
  background: none;
  cursor: url("../../../public/images/cursor06a.png") 5.5 0, auto;
  outline: 0;
  transform-style: preserve-3d;
  -webkit-tap-highlight-color: transparent;
}

.cursorDark {
  cursor: url("../../../public/images/cursor08a.png") 5.5 0, auto;
}

.interactionWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.4%/1.7%;
  background-color: transparent;
  transform-style: preserve-3d;

  /* setting the dimensions */
  @media (max-aspect-ratio: 2/5) {
    width: 96vw;
    height: 144vw;
  }

  @media (min-aspect-ratio: 2/5) and (max-aspect-ratio: 7/10) {
    width: 90vw;
    height: 135vw;
  }

  @media (min-aspect-ratio: 7/10) and (max-aspect-ratio: 1/1) {
    width: 64vh;
    height: 96vh;
  }

  @media (min-aspect-ratio: 1/1) {
    width: 60vh;
    height: 90vh;
  }
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 2.4%/1.7%;
  box-sizing: border-box;
  background-image: url("../../../public/images/pozadinazelena.png");
  background-size: contain;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.redBg {
  background-image: url("../../../public/images/pozadinacrvena.jpg");
}

.purpleBg {
  background-image: url("../../../public/images/pozadinaljubicasta.png");
}

.whiteBg {
  background-image: url("../../../public/images/pozadinabijela.jpg");
}

.faceWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 2.4%/1.7%;
  box-sizing: border-box;
}

.flipCardBack {
  transform: rotateY(180deg);
}
