.cardGlare {
  position: absolute;
  border-radius: 2.4%/1.7%;
  mix-blend-mode: overlay;

  /* setting the dimensions */
  @media (max-aspect-ratio: 2/5) {
    width: 96vw;
    height: 144vw;
  }

  @media (min-aspect-ratio: 2/5) and (max-aspect-ratio: 7/10) {
    width: 90vw;
    height: 135vw;
  }

  @media (min-aspect-ratio: 7/10) and (max-aspect-ratio: 1/1) {
    width: 64vh;
    height: 96vh;
  }

  @media (min-aspect-ratio: 1/1) {
    width: 60vh;
    height: 90vh;
  }
}
