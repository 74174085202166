.creditsWrapper {
  position: absolute;
  right: 5%;
  bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Aubrey", serif;
  z-index: 1000;

  &.open {
    right: 0%;
    bottom: 0%;
    margin: 4px 4px 40px 4px;

    @media (min-width: 680px) {
      margin: 4px 20px 4px 4px;
    }
  }
}

.credits {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  overflow: hidden;
  color: #fcfaed;
  font-size: 1.3vh;
  text-overflow: ellipsis;
  white-space: nowrap;
  writing-mode: vertical-rl;
  text-orientation: mixed;

  & > p {
    margin: 0;
  }

  @media (max-height: 520px) {
    font-size: 1.25vh;
  }

  @media (min-width: 680px) {
    writing-mode: horizontal-tb;
    text-orientation: mixed;
    font-size: 11px;
  }
}

.openButton {
  padding: 0;
  border: none;
  background: none;
  cursor: url("../../../public/images/cursor06a.png") 5.5 0, auto;
}

.img {
  height: 50px;
}

.image {
  position: absolute;
  right: unset;
  bottom: 25px;
  left: 0;
  width: 9px;
  height: 70vh;
  background-image: url("../../../public/images/paleta1.png");
  background-size: cover;
  z-index: 1000;

  @media (min-width: 680px) {
    right: 15px;
    bottom: 25px;
    left: unset;
    width: 25px;
    height: 120px;
  }
}
