:global(#root) {
  background-color: transparent;
}

.flipCardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: transparent;
}

.buttonWrapper {
  padding: 0;
  border: none;
  border-radius: 2.4%/1.7%;
  background: none;
  outline: 0;
}

.flipCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 2.4%/1.7%;
  color: inherit;
  background: none;
  outline: 0;
}

.interactionWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.4%/1.7%;
  background-color: transparent;
  transform-style: preserve-3d;

  width: 66.66vh;
  height: 100vh;
}

.flipCardFront {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 2.4%/1.7%;
  box-sizing: border-box;
  background-image: url("../../../public/images/pozadinazelena.png");
  background-size: contain;
  transform: translateZ(0);
}

.redBg {
  background-image: url("../../../public/images/pozadinacrvena.jpg");
}

.purpleBg {
  background-image: url("../../../public/images/pozadinaljubicasta.png");
}

.whiteBg {
  background-image: url("../../../public/images/pozadinabijela.jpg");
}

.faceWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 2.4%/1.7%;
  box-sizing: border-box;

  & > * {
    width: calc(66.66vh * 0.951);
    height: calc(100vh * 0.967);
  }
}
