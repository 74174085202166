.hoverCard {
  position: absolute;
  width: 52px;
  height: 77px;
  background-color: transparent;
  z-index: 999;

  & > img {
    width: 52px;
    height: 77px;
    border-radius: 1.5px;
  }
}
