.cardShineFirstLayer {
  position: absolute;
  border-radius: 2.4%/1.7%;
  background-image: url(./img/glitter.png),
    repeating-linear-gradient(
      110deg,
      rgba(89, 46, 80, 0.5) 0%,
      hsl(39, 37%, 60%) 2.5%,
      rgb(216, 183, 92) 5%,
      hsl(39, 37%, 60%) 7.5%,
      rgba(14, 21, 46, 0.5) 10%,
      rgba(14, 21, 46, 0.5) 15%
    ),
    url(./img/metal.png);
  background-size: 25% 25%, 700% 100%, 28% 23%;
  transform: translateZ(1px) scaleX(0.99) perspective(200px);
  mix-blend-mode: color-dodge;
  background-blend-mode: color-burn, darken;

  /* setting the dimensions */
  @media (max-aspect-ratio: 2/5) {
    width: 96vw;
    height: 144vw;
  }

  @media (min-aspect-ratio: 2/5) and (max-aspect-ratio: 7/10) {
    width: 90vw;
    height: 135vw;
  }

  @media (min-aspect-ratio: 7/10) and (max-aspect-ratio: 1/1) {
    width: 64vh;
    height: 96vh;
  }

  @media (min-aspect-ratio: 1/1) {
    width: 60vh;
    height: 90vh;
  }
}

.cardShineSecondLayer {
  position: absolute;
  border-radius: 2.4%/1.7%;
  background-image: url(./img/glitter.png),
    repeating-linear-gradient(
      110deg,
      rgba(89, 46, 80, 0.5) 0%,
      hsl(39, 37%, 60%) 2.5%,
      rgb(216, 183, 92) 5%,
      hsl(39, 37%, 60%) 7.5%,
      rgba(14, 21, 46, 0.5) 10%,
      rgba(14, 21, 46, 0.5) 15%
    );
  background-size: 25% 25%, 700% 100%;
  transform: translateZ(1px) scaleX(0.99) perspective(200px);
  mix-blend-mode: exclusion;
  background-blend-mode: color-burn, darken;

  /* setting the dimensions */
  @media (max-aspect-ratio: 2/5) {
    width: 96vw;
    height: 144vw;
  }

  @media (min-aspect-ratio: 2/5) and (max-aspect-ratio: 7/10) {
    width: 90vw;
    height: 135vw;
  }

  @media (min-aspect-ratio: 7/10) and (max-aspect-ratio: 1/1) {
    width: 64vh;
    height: 96vh;
  }

  @media (min-aspect-ratio: 1/1) {
    width: 60vh;
    height: 90vh;
  }
}
