.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  color: #fcfaed;
  background-image: url("../../../../public/images/pozadinateksta06a.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Aubrey", serif;
  text-align: center;
  z-index: 1;

  & > div {
    box-sizing: border-box;

    & > p {
      margin: 0;
      -webkit-user-select: none;
      user-select: none;
    }
  }

  /* setting the dimensions */
  @media (max-aspect-ratio: 2/5) {
    width: calc(96vw * 0.951);
    height: calc(144vw * 0.967);
  }

  @media (min-aspect-ratio: 2/5) and (max-aspect-ratio: 7/10) {
    width: calc(90vw * 0.951);
    height: calc(135vw * 0.967);
  }

  @media (min-aspect-ratio: 7/10) and (max-aspect-ratio: 1/1) {
    width: calc(64vh * 0.951);
    height: calc(96vh * 0.967);
  }

  @media (min-aspect-ratio: 1/1) {
    width: calc(60vh * 0.951);
    height: calc(90vh * 0.967);
  }
}
