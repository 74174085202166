.wrapper {
  position: absolute;
  bottom: 14px;
  left: 20px;
  cursor: url("../../../public/images/cursor06a.png") 5.5 0, auto;
  z-index: 1000;
}

.image {
  width: 120px;
}
